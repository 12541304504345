<template>
  <transition name="slide-x-reverse-transition">
    <div
      class="sidebar"
      v-if="getShowSidebar && getBreakpoints.mdAndDown"
    >
      <div class="sidebar--button">
        <span @click="navigate({ name: 'sell' })">
          {{ $t('components.nav.sell') }}
        </span>
      </div>
      <div
        class="sidebar--button border collapse"
        @click="(showRentExpand = !showRentExpand), (showSellExpand = false)"
      >
        <span>
          {{ $t('components.nav.rent') }}
        </span>
        <span
          class="mdi mdi-chevron-down"
          :class="showRentExpand && 'inverted'"
        />
      </div>
      <expand-transition>
        <div
          class="sidebar--subbutton-wrapper"
          v-if="showRentExpand"
        >
          <div class="sidebar--subbutton">
            <span
              @click="
                navigate({
                  name: 'rent',
                  query: {
                    rentType: 0,
                  },
                })
              "
            >
              {{ $t('components.nav.owner') }}
            </span>
          </div>
          <div class="sidebar--subbutton">
            <span
              @click="
                navigate({
                  name: 'rent',
                  query: {
                    rentType: 1,
                  },
                })
              "
            >
              {{ $t('components.nav.tenant') }}
            </span>
          </div>
        </div>
      </expand-transition>
      <!-- <div class="sidebar--button border">
        <span @click="navigate({ name: 'agents' })">
          {{ $t('components.nav.agents') }}
        </span>
      </div> -->
      <div class="sidebar--button border">
        <span @click="navigate({ name: 'properties' })">
          {{ $t('components.nav.properties') }}
        </span>
      </div>
      <div class="sidebar--button border">
        <span @click="navigate({ name: 'referral' })">
          {{ $t('components.nav.referral') }}
        </span>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Sidebar',
  data() {
    return {
      showSellExpand: false,
      showRentExpand: false,
    };
  },
  computed: {
    ...mapGetters(['getShowSidebar', 'getBreakpoints']),
  },
  methods: {
    navigate(to) {
      this.$router.push(to);
      this.$store.dispatch('toggleShowSidebar');
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: 70px;
  right: 0;
  height: 100%;
  width: 50%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  pointer-events: auto;
  z-index: 90;

  background: rgba(white, 0.95);
  box-shadow: 0px 4px 30px rgba($primary, 0.4);

  .sidebar--button {
    font-weight: 600;
    padding: 24px 0;

    span {
      cursor: pointer;
    }

    &.border {
      border-top: 1px solid rgba($primary, 0.2);
    }

    &.collapse {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mdi {
        font-size: 24px;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        transition: all 0.3s ease;

        &.inverted {
          transform: rotateZ(-180deg);
          transition: all 0.3s ease;
        }
      }
    }
  }
  .sidebar--subbutton {
    padding: 12px 0;

    span {
      cursor: pointer;
    }

    &-wrapper {
      padding-bottom: 12px;
    }
  }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .sidebar {
    background: rgba(white, 0.8);
    -webkit-backdrop-filter: blur(48px);
    backdrop-filter: blur(48px);
  }
}

@media only screen and (max-width: 660px) {
  .sidebar {
    width: 65%;
  }
}

@media only screen and (max-width: 520px) {
  .sidebar {
    width: 80%;
  }
}
</style>
