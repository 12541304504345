<template>
  <div class="footer">
    <div class="margin-container">
      <div class="row">
        <div class="col-md-6">
          <img
            class="logo"
            src="@/assets/svgs/logo_white.svg"
          >
          <div class="d-flex social-icons mt-6">
            <router-link
              to="/facebook"
              class="mr-2"
            >
              <span class="mdi mdi-facebook" />
            </router-link>
            <router-link
              to="/instagram"
              class="mr-2"
            >
              <span class="mdi mdi-instagram" />
            </router-link>
            <router-link
              to="/linkedin"
              class="mr-2"
            >
              <span class="mdi mdi-linkedin" />
            </router-link>
            <!-- <router-link
              to="/"
              class="mr-2"
            >
              <span class="mdi mdi-twitter" />
            </router-link>
            <router-link to="/">
              <span class="mdi mdi-youtube" />
            </router-link> -->
          </div>
        </div>
        <div class="col-md-6 links">
          <div
            class="row"
            v-if="getBreakpoints.mdAndUp"
          >
            <div class="col-4 d-flex flex-column">
              <router-link to="/">
                {{ $t('components.footer.sell') }}
              </router-link>
              <router-link to="/rent">
                {{ $t('components.footer.rent') }}
              </router-link>
              <router-link to="/agents">
                {{ $t('components.footer.agents') }}
              </router-link>
            </div>
            <div class="col-4 d-flex flex-column">
              <router-link to="/properties">
                {{ $t('components.footer.properties') }}
              </router-link>
              <router-link to="/process/sell-us">
                {{ $t('components.footer.sellUs') }}
              </router-link>
              <router-link to="/referral">
                {{ $t('components.footer.referral') }}
              </router-link>
            </div>
            <div class="col-4 d-flex flex-column">
              <router-link to="/blog">
                {{ $t('components.footer.blog') }}
              </router-link>
            </div>
          </div>
          <div
            class="row mt-5"
            v-else
          >
            <div class="col-6 d-flex flex-column">
              <router-link to="/">
                {{ $t('components.footer.sell') }}
              </router-link>
              <router-link to="/rent">
                {{ $t('components.footer.rent') }}
              </router-link>
              <router-link to="/agents">
                {{ $t('components.footer.agents') }}
              </router-link>
              <router-link to="/process/sell-us">
                {{ $t('components.footer.sellUs') }}
              </router-link>
            </div>
            <div class="col-6 d-flex flex-column">
              <router-link to="/properties">
                {{ $t('components.footer.properties') }}
              </router-link>
              <router-link to="/referral">
                {{ $t('components.footer.referral') }}
              </router-link>
              <router-link to="/blog">
                {{ $t('components.footer.blog') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background-color: $secondary;
  color: $tertiary;
  padding: 100px 0;

  .social-icons {
    a {
      font-size: 32px;
      color: $tertiary;
    }
  }

  .news-letter {
    &--text {
      font-weight: 500;
    }

    .form {
      width: 80%;
      flex-wrap: wrap;
    }
  }

  .btm-text {
    width: 80%;
    color: $grey;
    font-size: 14px;
  }

  .links {
    a {
      font-weight: 500;
      color: $tertiary;

      & + a {
        margin-top: 40px;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .footer {
    padding: 48px 0;

    .news-letter .form {
      width: 100%;
    }

    .btm-text {
      width: 100%;
    }

    .links {
      a + a {
        margin-top: 28px;
      }
    }
  }
}
</style>
