<template>
  <transition name="slide-x-reverse-transition">
    <div
      class="user-sidebar"
      v-if="getShowUserSidebar && getBreakpoints.mdAndDown"
    >
      <div class="user-sidebar__upper">
        <span
          class="mdi mdi-logout"
          @click="handleSignOut"
        />
        <div class="user">
          <img
            :src="require(`@/assets/svgs/user_placeholder.svg`)"
            class="user__image"
          >
          <span class="user__name">
            {{ getUser.name }}
          </span>
        </div>
        <span
          class="mdi mdi-account-cog-outline"
          @click="handleProfileNavigation"
        />
      </div>
      <div class="user-sidebar__divider" />
      <div class="user-sidebar__icons">
        <span
          class="mdi mdi-card-text-outline"
          @click="handleListingsNavigation"
        />
        <span class="mdi mdi-text-box-edit-outline" />
        <span class="mdi mdi-bell-outline" />
        <span class="mdi mdi-message-text-outline" />
        <span class="mdi mdi-calendar-month-outline" />
        <span class="mdi mdi-heart-outline" />
      </div>
      <div class="user-sidebar__divider" />
      <div
        class="user-sidebar__add-button"
        @click="handleAddPropertyNavigation"
      >
        <span class="mdi mdi-plus-circle-outline" />
        <span>
          {{ $t('components.userPopUp.addProperty') }}
        </span>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserSidebar',
  computed: {
    ...mapGetters(['getShowUserSidebar', 'getBreakpoints', 'getUser']),
  },
  methods: {
    async handleSignOut() {
      try {
        this.$store.dispatch('toggleShowUserSidebar');
        await this.$store.dispatch('signOut');
        this.$router.push({ path: '/' });
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
    handleProfileNavigation() {
      this.$store.dispatch('toggleShowUserSidebar');
      this.$router.push({ path: '/dashboard' });
    },
    handleListingsNavigation() {
      this.$store.dispatch('toggleShowUserSidebar');
      this.$router.push({ path: '/dashboard/listings' });
    },
    handleAddPropertyNavigation() {
      this.$store.dispatch('toggleShowUserSidebar');
      this.$router.push({ path: '/process/rent' });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-sidebar {
  position: fixed;
  top: 70px;
  right: 0;
  height: 100%;
  width: 50%;

  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  pointer-events: auto;
  z-index: 90;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;
  gap: 32px;

  background-color: rgba(white, 0.95);
  box-shadow: 0px 4px 30px 0px rgba($primary, 0.4);

  &__divider {
    width: 100%;
    height: 1px;
    background: rgba($primary, 0.2);
  }

  &__upper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .user {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      .user__image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        box-shadow: 0px 4px 30px rgba($primary, 0.4);
      }

      .user__name {
        font-size: 14px;
        font-weight: 600;
        color: $primary;
      }
    }

    .mdi {
      font-size: 28px;
      height: 28px;
      line-height: 1;
      aspect-ratio: 1;
      cursor: pointer;
      color: $grey;
    }
  }

  &__icons {
    display: flex;
    gap: 24px;

    .mdi {
      font-size: 24px;
      height: 24px;
      line-height: 1;
      aspect-ratio: 1;
      cursor: pointer;
    }
  }

  &__add-button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: $grey;
    font-weight: 600;

    .mdi {
      font-size: 24px;
      height: 24px;
      line-height: 1;
      aspect-ratio: 1;
    }
  }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .user-sidebar {
    background: rgba(white, 0.8);
    -webkit-backdrop-filter: blur(48px);
    backdrop-filter: blur(48px);
  }
}

@media only screen and (max-width: 660px) {
  .user-sidebar {
    width: 65%;
  }
}

@media only screen and (max-width: 520px) {
  .user-sidebar {
    width: 80%;
  }
}
</style>
