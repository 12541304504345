<template>
  <div class="header">
    <div
      class="margin-container"
      v-if="getBreakpoints.mdAndUp"
    >
      <div class="d-flex text-center align-items-stretch ma-0 py-5">
        <router-link to="/">
          <img
            :src="require(`@/assets/svgs/logo.svg`)"
            class="logo"
          >
        </router-link>
        <div class="header-nav mx-auto">
          <div
            class="header-nav--button mx-5"
            @click="navigate({ name: 'sell' })"
          >
            <div class="header-nav--button-inner">
              <span>
                {{ $t('components.nav.sell') }}
              </span>
            </div>
          </div>
          <div
            class="header-nav--button mx-5"
            @mouseleave="(e) => unhoverButton(e)"
          >
            <div
              class="header-nav--button-inner"
              @click="navigate({ name: 'rent' })"
              @mouseover="showRentCard = true"
            >
              <span>
                {{ $t('components.nav.rent') }}
              </span>
            </div>
            <transition name="fade-transition">
              <div
                class="header-nav--card rent"
                @mouseleave="(e) => unhoverButton(e)"
                v-if="showRentCard"
              >
                <div
                  class="header-nav--card-button"
                  @click="
                    navigate({
                      name: 'rent',
                      query: {
                        rentType: 0,
                      },
                    })
                  "
                >
                  <span>
                    {{ $t('components.nav.owner') }}
                  </span>
                </div>
                <div
                  class="header-nav--card-button"
                  @click="
                    navigate({
                      name: 'rent',
                      query: {
                        rentType: 1,
                      },
                    })
                  "
                >
                  <span>
                    {{ $t('components.nav.tenant') }}
                  </span>
                </div>
              </div>
            </transition>
          </div>
          <!-- <div class="header-nav--button mx-5">
            <div
              class="header-nav--button-inner"
              @click="navigate({ name: 'agents' })"
            >
              <span>
                {{ $t('components.nav.agents') }}
              </span>
            </div>
          </div> -->
          <div class="header-nav--button mx-5">
            <div
              class="header-nav--button-inner"
              @click="navigate({ name: 'properties' })"
            >
              <span>
                {{ $t('components.nav.properties') }}
              </span>
            </div>
          </div>
          <div class="header-nav--button mx-5">
            <div
              class="header-nav--button-inner"
              @click="navigate({ name: 'referral' })"
            >
              <span>
                {{ $t('components.nav.referral') }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="user-nav__logged-in"
          v-if="getUser"
        >
          <span>
            {{ $t('components.header.greetings', { name: getUser.name.split(' ')[0] }) }}
          </span>
          <div
            class="user"
            @click="showUserPopup = !showUserPopup"
          >
            <img
              :src="require(`@/assets/svgs/user_placeholder.svg`)"
              class="user__image"
            >
            <!-- TODO <div class="user__notification" /> -->
          </div>
          <transition name="fade-transition">
            <UserPopup
              @close="showUserPopup = false"
              v-if="showUserPopup"
            />
          </transition>
        </div>
        <div
          class="user-nav"
          v-else
        >
          <router-link to="/sign-up">
            {{ $t('components.header.signUp') }}
          </router-link>
          <BaseButton @click="$router.push({ name: 'sign-in' })">
            <template slot>
              <div class="sign-in-button">
                <span>
                  {{ $t('components.header.signIn') }}
                </span>
                <span class="mdi mdi-account-circle" />
              </div>
            </template>
          </BaseButton>
        </div>
      </div>
    </div>
    <div
      class="header--inner-mobile"
      v-else
    >
      <router-link to="/">
        <img
          :src="require(`@/assets/svgs/logo.svg`)"
          class="logo--mobile"
        >
      </router-link>
      <div class="buttons">
        <img
          :src="require(`@/assets/svgs/user_placeholder.svg`)"
          class="user_image"
          @click="toggleUserSidebar"
          v-if="getUser"
        >
        <img
          :src="require(`@/assets/svgs/mobile_login_icon.svg`)"
          class="login_icon"
          @click="$router.push({ name: 'sign-in' })"
          v-else
        >
        <CollapseButton />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BaseButton } from 'ff-components-lib';
import CollapseButton from '@/components/layout/CollapseButton';
import UserPopup from '@/components/layout/UserPopup';

export default {
  name: 'Header',
  components: {
    CollapseButton,
    UserPopup,
    BaseButton,
  },
  data() {
    return {
      showSellCard: false,
      showRentCard: false,
      showUserPopup: false,
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints', 'getShowSidebar', 'getUser']),
  },
  methods: {
    unhoverButton(e) {
      const target = e.relatedTarget;
      if (
        !target
        || !target.classList
        || (target.classList
          && !target.classList.contains('header-nav--card')
          && !target.classList.contains('header-nav--button-inner')
          && !target.classList.contains('header-nav--card-button'))
      ) {
        this.showSellCard = false;
        this.showRentCard = false;
      }
    },
    navigate(to) {
      this.$router.push(to);
    },
    toggleUserSidebar() {
      if (this.getShowSidebar) {
        this.$store.dispatch('toggleShowSidebar');
      }
      this.$store.dispatch('toggleShowUserSidebar');
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  background: rgba(230, 240, 255, 0.8);

  .logo {
    height: 50px;

    &--mobile {
      height: 38px;
    }
  }

  .header-nav {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;

    &--button {
      display: flex;
      align-items: center;
      position: relative;

      &-inner {
        cursor: pointer;
        font-weight: 500;
        color: $secondary;

        &:hover::before {
          position: absolute;
          top: calc(100% - 10px);
          width: 100%;
          height: 2px;
          background: $primary none repeat scroll 0% 0%;
          display: inline-block;
          content: '';
        }
      }
    }

    &--card {
      position: absolute;
      top: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 16px;
      width: var(--card-width);

      background: rgba(white, 0.95);
      box-shadow: 0px 4px 30px rgba($primary, 0.4);

      border-radius: 24px;

      &.sell {
        --card-width: 200px;
        left: calc(50% - 100px);
      }

      &.rent {
        --card-width: 146px;
        left: calc(50% - 73px);
      }

      &-button {
        cursor: pointer;
        font-weight: 500;
        color: $secondary;
        position: relative;

        &:hover::before {
          position: absolute;
          top: 100%;
          width: 100%;
          height: 2px;
          background: $primary none repeat scroll 0% 0%;
          display: inline-block;
          content: '';
        }
      }
    }
  }

  .join-us-button {
    display: flex;
    align-items: center;

    ::v-deep .base-button {
      background: unset;
      &:hover,
      &:active {
        background: unset;
      }
    }
  }

  .user-nav {
    display: flex;
    align-items: center;
    gap: 24px;

    a {
      color: $text-color;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .sign-in-button {
      display: flex;
      gap: 6px;

      .mdi {
        font-size: 20px;
        height: 20px;
        line-height: 1;
        aspect-ratio: 1;
      }
    }
  }

  .user-nav__logged-in {
    position: relative;
    display: flex;
    align-items: center;
    gap: 24px;

    color: $text-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    .user {
      position: relative;
      cursor: pointer;

      &__image {
        width: 36px;
        height: 36px;
        aspect-ratio: 1;
        border-radius: 50%;
      }

      &__notification {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: red;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .user__popup {
      position: absolute;
      top: 50px;
      right: 0;
    }
  }

  &--inner-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;

    .buttons {
      display: flex;
      align-items: center;
      gap: 24px;

      .user_image {
        width: 32px;
        height: 32px;
        aspect-ratio: 1;
        border-radius: 50%;
        cursor: pointer;
      }

      .login_icon {
        width: 26px;
        height: 26px;
        aspect-ratio: 1;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .header {
    background: rgba(230, 240, 255, 0.6);
    -webkit-backdrop-filter: blur(48px);
    backdrop-filter: blur(48px);

    .header-nav {
      &--card {
        background: rgba(white, 0.8);
        -webkit-backdrop-filter: blur(48px);
        backdrop-filter: blur(48px);
      }
    }
  }
}
</style>
