<template>
  <div
    class="collapse-button-container"
    :class="{ toggled: getShowSidebar || getShowUserSidebar }"
  >
    <button
      type="button"
      class="collapse-button"
      @click="toggleSidebar"
    >
      <span class="collapse-button-bar bar1" />
      <span class="collapse-button-bar bar2" />
      <span class="collapse-button-bar bar3" />
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CollapseButton',
  computed: {
    ...mapGetters(['getShowSidebar', 'getShowUserSidebar']),
  },
  methods: {
    toggleSidebar() {
      if (this.getShowUserSidebar) {
        this.$store.dispatch('toggleShowUserSidebar');
      } else {
        this.$store.dispatch('toggleShowSidebar');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/base/_animations.scss';

.collapse-button-container {
  button:focus {
    outline: none;
  }

  .collapse-button {
    padding: 4px 0px;
    background-color: transparent;
    border: 0px solid transparent;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.collapse-button-bar {
  display: block;
  position: relative;
  height: 1px;
  border-radius: 1px;
  background: $text-color;
}

.toggled .collapse-button-bar {
  width: 24px;

  & + .collapse-button-bar {
    margin-top: 5px;
  }
}

.bar1,
.bar2,
.bar3 {
  outline: 1px solid transparent;
}
.bar1 {
  top: 0px;
  width: 24px;
  @include bar-animation(topbar-back);
}
.bar2 {
  opacity: 1;
  margin-top: 7px;
  width: 24px;
  transition: width 0.2s linear;
}
.bar3 {
  bottom: 0px;
  width: 24px;
  margin-top: 7px;
  @include bar-animation(bottombar-back);
}
.toggled .bar1 {
  top: 6px;
  @include bar-animation(topbar-x);
}
.toggled .bar2 {
  opacity: 0;
}
.toggled .bar3 {
  bottom: 6px;
  @include bar-animation(bottombar-x);
}
</style>
