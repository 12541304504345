<template>
  <div class="user__popup">
    <div class="user__popup__upper">
      <span
        class="mdi mdi-logout"
        @click="handleSignOut"
      />
      <div class="user">
        <img
          :src="require(`@/assets/svgs/user_placeholder.svg`)"
          class="user__image"
        >
        <span class="user__name">
          {{ getUser.name }}
        </span>
      </div>
      <span
        class="mdi mdi-account-cog-outline"
        @click="handleProfileNavigation"
      />
    </div>
    <div class="user__popup__divider" />
    <div class="user__popup__icons">
      <span
        class="mdi mdi-card-text-outline"
        @click="handleListingsNavigation"
      />
      <!-- TODO
      <span class="mdi mdi-text-box-edit-outline" />
      <span class="mdi mdi-bell-outline" />
      <span class="mdi mdi-message-text-outline" />
      <span class="mdi mdi-calendar-month-outline" /> -->
      <span
        class="mdi mdi-heart-outline"
        @click="handleFavoritesNavigation"
      />
    </div>
    <div class="user__popup__divider" />
    <div
      class="user__popup__add-button"
      @click="handleAddPropertyNavigation"
    >
      <span class="mdi mdi-plus-circle-outline" />
      <span>
        {{ $t('components.userPopUp.addProperty') }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserPopup',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getBreakpoints', 'getUser']),
  },
  methods: {
    async handleSignOut() {
      try {
        this.$emit('close');
        await this.$store.dispatch('signOut');
        this.$router.push({ path: '/' });
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
    handleProfileNavigation() {
      this.$emit('close');
      this.$router.push({ path: '/dashboard' });
    },
    handleListingsNavigation() {
      this.$emit('close');
      this.$router.push({ path: '/dashboard/listings' });
    },
    handleFavoritesNavigation() {
      this.$emit('close');
      this.$router.push({ path: '/dashboard/favorites' });
    },
    handleAddPropertyNavigation() {
      this.$emit('close');
      this.$router.push({ path: '/process/rent' });
    },
  },
};
</script>
<style lang="scss" scoped>
.user__popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 318px;
  border-radius: 24px 0px 24px 24px;
  background-color: rgba(white, 0.95);
  box-shadow: 0px 4px 30px 0px rgba($primary, 0.4);

  .user__popup__divider {
    width: 100%;
    height: 1px;
    background: rgba($primary, 0.2);
  }

  .user__popup__upper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .user {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      .user__image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        box-shadow: 0px 4px 30px rgba($primary, 0.4);
      }

      .user__name {
        font-size: 14px;
        font-weight: 600;
        color: $primary;
      }
    }

    .mdi {
      font-size: 28px;
      height: 28px;
      line-height: 1;
      aspect-ratio: 1;
      cursor: pointer;
      color: $grey;
    }
  }

  .user__popup__icons {
    display: flex;
    gap: 24px;

    .mdi {
      font-size: 24px;
      height: 24px;
      line-height: 1;
      aspect-ratio: 1;
      cursor: pointer;
    }
  }

  .user__popup__add-button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: $grey;
    font-weight: 600;

    .mdi {
      font-size: 24px;
      height: 24px;
      line-height: 1;
      aspect-ratio: 1;
    }
  }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .user__popup {
    background-color: rgba(white, 0.8);
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
  }
}
</style>
